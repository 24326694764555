export * from './cameras.service';
import { CamerasService } from './cameras.service';
export * from './chat.service';
import { ChatService } from './chat.service';
export * from './control.service';
import { ControlService } from './control.service';
export * from './interactions.service';
import { InteractionsService } from './interactions.service';
export * from './training.service';
import { TrainingService } from './training.service';
export const APIS = [CamerasService, ChatService, ControlService, InteractionsService, TrainingService];
