/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TurnDirection = 'center' | 'up' | 'down' | 'left' | 'right';

export const TurnDirection = {
    Center: 'center' as TurnDirection,
    Up: 'up' as TurnDirection,
    Down: 'down' as TurnDirection,
    Left: 'left' as TurnDirection,
    Right: 'right' as TurnDirection
};

