<div class="container-actions">
    <div class="interactions">
        <h3 class="sub-header"
            i18n="interactions header|headline to be displayed for the interactions@@interactionsHeader">Gesten &
            Bewegungen</h3>
        <div class="interactions-type-selection">
            <uux-filter-pill
                [ngClass]="{ selected: currentInteractionTypeId === null }"
                (click)="currentInteractionChange(null)">
                <uux-label i18n="@@Label.All-Interactions">Alle</uux-label>
            </uux-filter-pill>
            <uux-filter-pill [ngClass]="{ selected: currentInteractionTypeId === 'action' }"
                             class="action"
                             (click)="currentInteractionChange('action')">
                <uux-label i18n="@@Label.Interactions-Actions">Aktion</uux-label>
            </uux-filter-pill>
            <uux-filter-pill [ngClass]="{ selected: currentInteractionTypeId === 'gesture' }"
                             class="gesture"
                             (click)="currentInteractionChange('gesture')">
                <uux-label i18n="@@Label.Interactions-Gestures">Gesten</uux-label>
            </uux-filter-pill>
            <uux-filter-pill [ngClass]="{ selected: currentInteractionTypeId === 'audio' }"
                             class="audio"
                             (click)="currentInteractionChange('audio')">
                <uux-label i18n="@@Label.Interactions-Audio">Audio</uux-label>
            </uux-filter-pill>
        </div>
        <div class="interactions-actions">
            <ng-container *ngFor="let actionType of interactions">
                <uux-action-pill
                    *ngIf="(!currentInteractionTypeId || actionType.interactionType === currentInteractionTypeId)"
                    class="{{actionType.interactionType}}"
                    (click)="executeAction(actionType)">
                    <uux-label>{{ actionType.name }}</uux-label>
                </uux-action-pill>
            </ng-container>
        </div>
    </div>
    <div class="specials"
         *ngIf="productTypes?.length">
        <h3 class="sub-header"
            i18n="specials header|headline to be displayed for the specials@@specialsHeader">Produkte</h3>
        <div class="specials-container">
            <div class="specials-type-selection">
                <uux-filter-pill *ngFor="let specialsType of specialsTypes"
                                 class="vertical"
                                 [ngClass]="{ selected: currentSpecialsTypeId === specialsType.id }"
                                 (click)=currentSpecialChange(specialsType)>
                    <uux-label>{{ specialsType.name }}</uux-label>
                </uux-filter-pill>
            </div>
            <div class="specials-actions">
                <div class="scroll-content">
                    <uux-product-action-pill *ngFor="let productType of productTypes"
                                             class="{{productType.interactionType}}"
                                             [duration]="productType.duration"
                                             (click)="executeAction(productType)">
                        <uux-label main-title>{{ productType.name }}</uux-label>
                        <uux-label description>{{ productType.description }}</uux-label>
                    </uux-product-action-pill>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-chat">
    <div class="chat-content"
         #chatScrollContainer>
        <div class="scroll-content">
            <div *ngFor="let chatMessage of chatMessages; index as i"
                 class="chat-object"
                 [ngClass]="{
                     'chat-customer' : chatMessage.kind === KindEnum.ChatMessage && chatMessage.sender === ChatParticipant.Customer,
                     'chat-robot' : chatMessage.kind === KindEnum.ChatMessage && (chatMessage.sender === ChatParticipant.Robot || chatMessage.sender === ChatParticipant.Operator) || chatMessage.kind === KindEnum.InteractionMessage || chatMessage.kind === KindEnum.ProductMessage
                    }">

                <h3 *ngIf="chatShouldInsertDateHeader(chatMessage, i)"
                    class="sub-header">
                    <span i18n="date prefix|prefix for today or yesterday displayed before the text@@datePrefix">{chatInsertDatePrefix(chatMessage.timestamp), select, yesterday {Gestern} today {Heute}}</span>
                    {{chatMessage.timestamp | date:'d.MMMM y'}}
                </h3>

                <!--ChatCustomer-->
                <div *ngIf="chatMessage.kind === 'ChatMessage' && chatMessage.sender === 'customer'">
                    <uux-chat-timestamp class="{{ chatMessage.sender }}"
                                        timestamp="{{ chatMessage.timestamp }}"></uux-chat-timestamp>

                    <!--Customer-->
                    <uux-chat-customer [duration]="chatMessage.duration"
                                       (playRecordedAudio)="customerPlayRecordedAudio(chatMessage.id)"
                                       (markForTraining)="customerMarkForTraining(chatMessage.id)"
                                       [ngClass]="{ playing: currentPlayingAudioId === chatMessage.id, bookmarked: false }">
                        <uux-label>{{ chatMessage.message }}</uux-label>
                    </uux-chat-customer>
                </div>

                <!--ChatRobot-->
                <div *ngIf="chatMessage.kind === KindEnum.ChatMessage &&(chatMessage.sender === ChatParticipant.Robot || chatMessage.sender === ChatParticipant.Operator)">
                    <uux-chat-timestamp class="{{ chatMessage.sender }}"
                                        timestamp="{{ chatMessage.timestamp }}"></uux-chat-timestamp>

                    <!--Robot or Operator-->
                    <uux-chat-robot>
                        <uux-label>{{ chatMessage.message }}</uux-label>
                        <uux-label *ngIf="chatMessage.action"
                                   class="chat-action {{ chatMessage.action.interactionType }}">{{ chatMessage.action.name }}</uux-label>
                    </uux-chat-robot>
                </div>

                <!--Interaction-->
                <uux-label *ngIf="chatMessage.kind === KindEnum.InteractionMessage"
                           class="chat-action {{ chatMessage.action.interactionType }}">{{ chatMessage.action.name }}</uux-label>

                <!--Product-->
                <uux-chat-robot *ngIf="chatMessage.kind === KindEnum.ProductMessage"
                                class="product-action">
                    <uux-label>{{ chatMessage.message }}</uux-label>
                </uux-chat-robot>


                <!--Information-->
                <uux-chat-timestamp *ngIf="chatMessage.kind === KindEnum.InformationMessage"
                                    class="interaction"
                                    timestamp="{{ chatMessage.timestamp }}">
                    <uux-label>{{ chatMessage.message }}</uux-label>
                </uux-chat-timestamp>
            </div>
        </div>
    </div>
    <div class="chat-input-container">
        <uux-chat
            #chatComponent
            (sendPressed)="chatSendPressed($event)"
            (recordPressed)="chatRecordPressed($event)"
            [ngClass]="{ recording: chatRecording }"></uux-chat>
    </div>
</div>
<div class="container-camera-streams"
     *ngIf="pepperCamera || radarCamera || topCamera">
    <div class="stream-container"
         (click)="openControlOverlay()"
         *ngIf="pepperCamera && !overlay">
        <h3 class="sub-header"
            i18n="stream view header|headline to be displayed for the stream displaying the current view@@streamViewHeader">
            Sichtfeld</h3>
        <ng-container
            [ngSwitch]="pepperCamera | needsIframe">
            <img
                alt="camera stream"
                [src]="pepperCamera"
                *ngSwitchCase="true"
            />
            <video
                *ngSwitchCase="false"
                [src]="pepperCamera"
                autoplay="true"></video>
        </ng-container>

    </div>
    <div class="stream-container"
         *ngIf="radarCamera">
        <h3 class="sub-header"
            i18n="stream radar header|headline to be displayed for the stream displaying the current technical view@@streamRadarHeader">
            Radar</h3>
        <ng-container
            [ngSwitch]="radarCamera | needsIframe">
            <iframe
                *ngSwitchCase="true"
                [src]="radarCamera"></iframe>
            <video
                *ngSwitchCase="false"
                [src]="radarCamera"
                autoplay="true"></video>
        </ng-container>
    </div>
    <div class="stream-container"
         *ngIf="topCamera">
        <h3 class="sub-header"
            i18n="stream interior header|headline to be displayed for the stream displaying the interior view@@streamInteriorHeader">
            Kassenbereich</h3>
        <ng-container
            [ngSwitch]="topCamera | needsIframe">
            <iframe
                *ngSwitchCase="true"
                [src]="topCamera"
            ></iframe>
            <video
                *ngSwitchCase="false"
                [src]="topCamera"
                autoplay="true"></video>
        </ng-container>
    </div>
</div>
<ng-template
    #controlDialog>
    <uux-camera-stream
        style="min-height: 80vh; width: 80vw;"
        (keydown)="keydown($event)"
        (keyup)="keyup($event)">
        <ng-container
            [ngSwitch]="pepperCamera | needsIframe">
            <img width="100%"
                 *ngSwitchCase="true"
                 [src]="pepperCamera"
                 alt="camera stream"
            />
            <video
                *ngSwitchCase="false"
                [src]="pepperCamera"
                autoplay="true"></video>
        </ng-container>
        <uux-icon-button
            (mousedown)="moveHead(TurnDirection.Up)"
            (mouseup)="moveHead(TurnDirection.Center)"
            (mouseleave)="moveHead(TurnDirection.Center)"
            icon="keyboard_arrow_up"
            top></uux-icon-button>
        <uux-icon-button
            (mousedown)="moveHead(TurnDirection.Left)"
            (mouseup)="moveHead(TurnDirection.Center)"
            (mouseleave)="moveHead(TurnDirection.Center)"
            icon="keyboard_arrow_left"
            left></uux-icon-button>
        <uux-icon-button
            (mousedown)="moveHead(TurnDirection.Right)"
            (mouseup)="moveHead(TurnDirection.Center)"
            (mouseleave)="moveHead(TurnDirection.Center)"
            icon="keyboard_arrow_right"
            right></uux-icon-button>
        <uux-icon-button
            (mousedown)="moveHead(TurnDirection.Down)"
            (mouseup)="moveHead(TurnDirection.Center)"
            (mouseleave)="moveHead(TurnDirection.Center)"
            icon="keyboard_arrow_down"
            bottom></uux-icon-button>
        <!--<uux-icon-button
            (click)="closeControlOverlay()"
            icon="call_split"
            class="large accent"
            control></uux-icon-button>-->
    </uux-camera-stream>
</ng-template>
