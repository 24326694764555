/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChatParticipant = 'robot' | 'customer' | 'operator';

export const ChatParticipant = {
    Robot: 'robot' as ChatParticipant,
    Customer: 'customer' as ChatParticipant,
    Operator: 'operator' as ChatParticipant
};

