<div class="text-action-container">
    <textarea
        #textArea
        [(ngModel)]="textAreaContent"
        (keyup.enter)="clickSend()"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (keydown.tab)="$event.preventDefault(); textArea.blur()"
        [readOnly]="recording"
        i18n-placeholder="textarea placeholder|placeholder to be displayed on the input area@@textareaPlaceholder"
        placeholder="Nachricht eintippen"></textarea>
    <div class="mic-overlay">
        <div class="icon icon-mic"></div>
        <span i18n="microphone on|displayed text if the microphone is turned on on the input area@@micOn">Mikrofon Ein</span>
    </div>
    <div class="action-container">
        <div class="record-btn icon icon-mic"
             [class.recording]="recording"
             (click)="clickRecord()"></div>
        <div class="send-btn icon icon-send"
             (click)="clickSend()"></div>
    </div>
</div>
<div class="additional-actions">
    <ng-content></ng-content>
</div>
