import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'uux-chat-customer',
  templateUrl: './chat-customer.component.html',
  styleUrls: ['./chat-customer.component.scss'],
})
export class ChatCustomerComponent {
  /** the message id */
  @Input() messageId: string;

  /** Length of recorded audio in seconds, will be parsed as minutes:seconds */
  @Input() duration: number;

  /** emitted when mark for training was clicked */
  @Output() readonly markForTraining: EventEmitter<any> = new EventEmitter<any>();

  /** emitted when the play button was clicked */
  @Output() readonly playRecordedAudio: EventEmitter<void> = new EventEmitter();

  /** emit that the play button was pressed */
  clickPlayRecordedAudio(): void {
    this.playRecordedAudio.emit();
  }

  /** emit that the mark button was pressed */
  clickMarkForTraining(): void {
    this.markForTraining.emit();
  }
}
