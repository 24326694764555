/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MoveDirection = 'forward' | 'backward' | 'left' | 'right' | 'turn-left' | 'turn-right' | 'stop';

export const MoveDirection = {
    Forward: 'forward' as MoveDirection,
    Backward: 'backward' as MoveDirection,
    Left: 'left' as MoveDirection,
    Right: 'right' as MoveDirection,
    TurnLeft: 'turn-left' as MoveDirection,
    TurnRight: 'turn-right' as MoveDirection,
    Stop: 'stop' as MoveDirection
};

