import { MainChatScreenModule } from './screens/main-chat-screen/main-chat-screen.module';
import { NavButtonModule } from './components/nav-button/nav-button.module';
import { LabelModule } from './components/label/label.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './Api';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, LabelModule, NavButtonModule, MainChatScreenModule, HttpClientModule],
  providers: [
    {
      provide: Configuration,
      useFactory: () => {
        return new Configuration({ basePath: '/api' });
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
