import { Component, Input } from '@angular/core';

@Component({
  selector: 'uux-product-action-pill',
  templateUrl: './product-action-pill.component.html',
  styleUrls: ['./product-action-pill.component.scss'],
})
export class ProductActionPillComponent {
  /**
   * estimated duration of the selected action
   */
  @Input() duration: number;
}
