/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProductMessage { 
    kind: ProductMessage.KindEnum;
    /**
     * Product message to be displayed inside the chat
     */
    message?: string;
    timestamp?: string;
}
export namespace ProductMessage {
    export type KindEnum = 'ProductMessage';
    export const KindEnum = {
        ProductMessage: 'ProductMessage' as KindEnum
    };
}


