import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'needsIframe',
  pure: true,
})
export class NeedsIframePipe implements PipeTransform {
  transform(value: string | SafeValue, ...args: Array<unknown>): boolean {
    const url: string = typeof value === 'string' ? value : (value as any).changingThisBreaksApplicationSecurity;

    return url.startsWith('http') || !url.includes('://');
  }
}
