<div class="stream">
    <ng-content></ng-content>
</div>
<div class="overlay hover-overlay top">
    <ng-content select="[top]"></ng-content>
</div>
<div class="overlay hover-overlay left">
    <ng-content select="[left]"></ng-content>
</div>
<div class="overlay hover-overlay right">
    <ng-content select="[right]"></ng-content>
</div>
<div class="overlay hover-overlay bottom">
    <ng-content select="[bottom]"></ng-content>
</div>
<div class="overlay control">
    <ng-content select="[control]"></ng-content>
</div>
