<div class="navigation">
    <div class="logo-container"><img src="./assets/logo.png"></div>
    <div class="navigation-buttons">
        <uux-nav-button [routerLink]="'Chat'" [routerLinkActive]="'selected'" [icon]="'robot'"><uux-label>Chat</uux-label></uux-nav-button>
        <uux-nav-button [routerLink]="'Training'" [routerLinkActive]="'selected'" [icon]="'auto-fix'" [badge]="'1'"><uux-label>Training</uux-label></uux-nav-button>
        <uux-nav-button [routerLink]="'System'" [routerLinkActive]="'selected'" [icon]="'build'"><uux-label>System</uux-label></uux-nav-button>
        <uux-nav-button [routerLink]="'User'" [routerLinkActive]="'selected'" [icon]="'person'"><uux-label>Nutzer</uux-label></uux-nav-button>
    </div>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
