import { Component, Input } from '@angular/core';

@Component({
  selector: 'uux-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  /** The Icon to be displayed */
  @Input() icon: string;

  /** Boolean value to check if button is disabled */
  @Input() disabled = false;
}
