import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatCustomerComponent } from './chat-customer.component';

@NgModule({
  declarations: [ChatCustomerComponent],
  imports: [CommonModule],
  exports: [ChatCustomerComponent],
})
export class ChatCustomerModule {}
