/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InteractionMessage } from './interactionMessage';
import { ChatParticipant } from './chatParticipant';


export interface ChatMessage { 
    kind: ChatMessage.KindEnum;
    /**
     * Content of chat message
     */
    message: string;
    /**
     * ID  of the interaction category
     */
    id?: string;
    timestamp?: string;
    sender?: ChatParticipant;
    /**
     * duration of the spoken message in seconds
     */
    duration?: number;
    action?: InteractionMessage;
}
export namespace ChatMessage {
    export type KindEnum = 'ChatMessage' | 'InformationMessage' | 'Gesture' | 'InteractionMessage' | 'ProductMessage';
    export const KindEnum = {
        ChatMessage: 'ChatMessage' as KindEnum,
        InformationMessage: 'InformationMessage' as KindEnum,
        Gesture: 'Gesture' as KindEnum,
        InteractionMessage: 'InteractionMessage' as KindEnum,
        ProductMessage: 'ProductMessage' as KindEnum
    };
}


