import { Component, Input } from '@angular/core';

@Component({
  selector: 'uux-chat-timestamp',
  templateUrl: './chat-timestamp.component.html',
  styleUrls: ['./chat-timestamp.component.scss'],
})

/**
 * This Component has the following, exclusive, variants:
 *  - With label (through ng-content)
 *  - With icon (set through CSS classes .robot and .customer on the host)
 *    - With Robot icon
 *    - With Customer icon
 *
 *  If both a label and an icon class are present, the icon will take
 *  precedence
 *
 *  The icon based variants Customer and Robot align to the left and right of
 *  the host component respectively. The label variant is centered.
 *
 *  Rendered, this component should look like
 *  <timestamp> <icon || label> for the Customer icon and label variant
 *  and
 *  <icon> <timestamp> for the Robot icon variant
 *
 */
export class ChatTimestampComponent {
  /***
   * Represents the timestamp of a message in the chat window
   * Format: HH:mm (zero padded hours : zero padded minutes)
   */
  @Input() timestamp: string;
}
