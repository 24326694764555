<div class="main-container">
    <div class="left-container">
        <div class="main-title-container">
            <ng-content select="[main-title]"></ng-content>
        </div>
        <div class="description-container">
            <ng-content select="[description]"></ng-content>
        </div>
        <div class="information-container">
            <span class="duration">
                {{duration}}
            </span>
            <span class="duration-suffix"
                  i18n="seconds unit|the short unit for seconds@@secondsUnit">
                 &nbsp;sec
            </span>

            <div class="icon motion-icon icon-accessibility"></div>
            <div class="icon audio-icon icon-volume_up"></div>

        </div>
    </div>
    <div class="right-container">
        <div class="icon settings-icon icon-more_horiz"></div>
    </div>
</div>
