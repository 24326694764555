import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPillComponent } from './filter-pill.component';

@NgModule({
  declarations: [FilterPillComponent],
  imports: [CommonModule],
  exports: [FilterPillComponent],
})
export class FilterPillModule {}
