<div class="container">
    <div class="message-container">
        <ng-content></ng-content>
        <div class="divider"></div>
        <div class="play-container">
            <span class="play-btn icon"
                  (click)="clickPlayRecordedAudio()"></span>
            <span class="duration">{{duration * 1000 | date:"mm:ss"}}</span>
            <span class="duration-suffix" i18n="minutes unit|the short unit for minutes@@minutesUnit">min</span>
        </div>
    </div>
    <div class="mark-container">
        <div class="mark-action icon"
             (click)="clickMarkForTraining()"></div>
        <div class="border-bottom"></div>
    </div>
</div>
