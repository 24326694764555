import { Component, Input } from '@angular/core';

@Component({
  selector: 'uux-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent {
  /** The Icon to be displayed */
  @Input() icon: string;

  /** The text of the badge to be displayed */
  @Input() badge?: string;
}
