import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'uux-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  /** emitted when the send button was clicked */
  @Output() readonly sendPressed: EventEmitter<string> = new EventEmitter<string>();

  /** emitted when the record button was clicked */
  @Output() readonly recordPressed: EventEmitter<void> = new EventEmitter();

  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;

  /** the text area model bound variable */
  textAreaContent = '';

  recording: boolean;
  private speechRecognition: SpeechRecognition;

  /** emit that the send button was pressed */
  clickSend(): void {
    if (this.textAreaContent && this.textAreaContent.length > 0) {
      this.sendPressed.emit(this.textAreaContent);
      this.textAreaContent = '';
    }
  }

  /** emit that the record button was pressed */
  clickRecord(): void {
    if (this.recording) {
      this.recording = false;
      this.speechRecognition.stop();
    } else {
      this.recording = true;
      this.speechRecognition.start();
    }
  }

  speechResult(event: SpeechRecognitionEvent): any {
    for (let i = event.resultIndex; i < event.results.length; i += 1) {
      if (event.results[i].isFinal) {
        this.textAreaContent += event.results[i][0].transcript;
      }
    }
  }

  ngOnInit(): void {
    const speechRecognitionConstructor = window.SpeechRecognition || (window as any).webkitSpeechRecognition;
    this.speechRecognition = new speechRecognitionConstructor();
    this.speechRecognition.continuous = true;
    this.speechRecognition.interimResults = true;
    this.speechRecognition.addEventListener('result', (ev) => this.speechResult(ev));
  }

  focus(): void {
    this.textArea.nativeElement.focus();
  }
}
