/**
 * UUX Wizard of Oz API
 * UUX API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InteractionType = 'action' | 'gesture' | 'audio';

export const InteractionType = {
    Action: 'action' as InteractionType,
    Gesture: 'gesture' as InteractionType,
    Audio: 'audio' as InteractionType
};

