import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductActionPillComponent } from './product-action-pill.component';

@NgModule({
  declarations: [ProductActionPillComponent],
  imports: [CommonModule],
  exports: [ProductActionPillComponent],
})
export class ProductActionPillModule {}
