import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  CameraPosition,
  CamerasService,
  CameraStream,
  ChatMessage,
  ChatParticipant,
  ChatService,
  ControlService,
  Interaction,
  InteractionCategory,
  InteractionsResponse,
  InteractionsService,
  MoveDirection,
  TurnDirection,
} from '../../Api';
import { webSocket } from 'rxjs/webSocket';
import { DOCUMENT } from '@angular/common';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { merge, of, Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { OverlayRef } from '@angular/cdk/overlay/overlay-ref';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChatComponent } from '../../components/chat/chat.component';
import { ActivatedRoute, ParamMap } from '@angular/router';

enum MoveTarget {
  Robot = 'Robot',
  Head = 'Head',
}

/* tslint:disable: template-no-call-expression */
@Component({
  selector: 'uux-main-chat-screen',
  templateUrl: './main-chat-screen.component.html',
  styleUrls: ['./main-chat-screen.component.scss'],
})
export class MainChatScreenComponent implements OnDestroy {
  // a view reference to the chat container
  @ViewChild('chatScrollContainer') chatScrollContainer: ElementRef;

  //#region members

  // cameraStreams
  pepperCamera?: SafeResourceUrl;
  radarCamera?: SafeResourceUrl;
  topCamera?: SafeResourceUrl;

  @ViewChild('controlDialog', { read: TemplateRef, static: true }) templateRef: TemplateRef<any>;
  overlay: OverlayRef;

  MoveDirection = MoveDirection;
  TurnDirection = TurnDirection;

  // interaction filter pill
  currentInteractionTypeId = null;

  // interaction actions
  actionTypes: Array<Interaction> = [];

  // specials filter pill
  currentSpecialsTypeId = '';

  // interaction actions
  productTypes: Array<Interaction> = [];

  interactions: Array<Interaction> = [];

  // chat data
  chatMessages: Array<ChatMessage> = [];
  currentPlayingAudioId = -1;
  chatRecording = false;

  KindEnum = ChatMessage.KindEnum;
  ChatParticipant = ChatParticipant;

  actualMovementDirection: MoveDirection = undefined;

  actualTurnDirection: TurnDirection = undefined;

  MoveTarget = MoveTarget;
  actualTarget = MoveTarget.Robot;

  @ViewChild('chatComponent', { read: ChatComponent, static: true }) chatComponent: ChatComponent;

  private _specialsTypes: Array<InteractionCategory> = [];
  private _interactionTypes: Array<InteractionCategory> = [];

  private readonly _destroySubject = new Subject<void>();

  private delayStopMovement;
  private delayStopHead;
  //#endregion

  //#region ctor
  private selectedRobot: string;

  /** initialize chat view with respective api calls  */
  constructor(
    private readonly chatService: ChatService,
    private readonly interactionsService: InteractionsService,
    private readonly camerasService: CamerasService,
    private readonly controlService: ControlService,
    private readonly sanitizer: DomSanitizer,
    @Inject(DOCUMENT) document: Document,
    private readonly overlayService: Overlay,
    private readonly viewContainer: ViewContainerRef,
    @Optional() private readonly activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute?.paramMap
      ?.pipe(
        switchMap((params: ParamMap) => {
          const robot: string = params.get('robot');
          if (robot === '') {
            return of(undefined);
          }

          return of(robot);
        }),
        distinctUntilChanged()
      )
      ?.subscribe((robot) => (this.selectedRobot = robot));

    merge(
      webSocket<{ update: 'chat' | 'control' }>(
        `${document.location.protocol.endsWith('s:') ? 'wss' : 'ws'}://${document.location.host}/api`
      ).pipe(
        filter((value) => {
          return value.update === 'chat';
        }),
        catchError((err, caught) => {
          console.warn('lost socket connection, try reconnecting');

          return caught;
        })
      ),
      of(1)
    )
      .pipe(
        takeUntil(this._destroySubject),
        switchMap(() => {
          return this.chatService.retrieveChatMessages();
        })
      )
      .subscribe((x) => {
        x.messages?.sort((a, b) => {
          return Date.parse(a.timestamp) - Date.parse(b.timestamp);
        });
        this.chatMessages = x.messages;
        setTimeout(() => {
          this.chatScrollToBottom();
        }, 0);
      });
    this.interactionsService
      .retrieveInteractions()
      .toPromise()
      .then((res: InteractionsResponse) => {
        this.interactions = res && res.interactions ? res.interactions : [];
      })
      .catch((err) => {
        console.error('Could not retrieve interactions.');
      });
    /*chatApiService.getSpecials()
      .then((specials) => {
        this.specialsTypes = specials;
      });*/
    this.camerasService
      .retrieveCameraStreams(this.selectedRobot)
      .toPromise()
      .then((response) => {
        this.pepperCamera = this.findAndSanitizeCamera(response.cameras, CameraPosition.Robot);
        this.topCamera = this.findAndSanitizeCamera(response.cameras, CameraPosition.Top);
      });
  }

  //#endregion

  //#region properties

  set interactionTypes(interactionArray: Array<InteractionCategory>) {
    this._interactionTypes = interactionArray;

    // this.currentInteractionTypeId = interactionArray[0].id;
    this.actionTypes = interactionArray[0].interactions;
  }

  get interactionTypes(): Array<InteractionCategory> {
    return this._interactionTypes;
  }

  set specialsTypes(specialArray: Array<InteractionCategory>) {
    this._specialsTypes = specialArray;

    this.currentSpecialsTypeId = specialArray[0].id;
    this.productTypes = specialArray[0].interactions;
  }

  get specialsTypes(): Array<InteractionCategory> {
    return this._specialsTypes;
  }

  openControlOverlay(): void {
    if (this.overlay) {
      return;
    }
    this.overlay = this.overlayService.create({
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
      disposeOnNavigation: true,
    });
    this.overlay
      .backdropClick()
      .pipe(takeUntil(this._destroySubject))
      .subscribe((value) => {
        this.closeControlOverlay();
      });
    this.overlay.attach(new TemplatePortal(this.templateRef, this.viewContainer));
  }

  closeControlOverlay(): void {
    if (!this.overlay) {
      return;
    }
    this.overlay.detach();
    this.overlay.dispose();
    this.overlay = undefined;
  }

  toggleTarget(): void {
    this.actualTarget = this.actualTarget === MoveTarget.Robot ? MoveTarget.Head : MoveTarget.Robot;
  }
  moveRobot(direction: MoveDirection): void {
    if (this.actualMovementDirection === direction) {
      return;
    }
    this.actualMovementDirection = direction;
    this.controlService.moveRobot(direction, this.selectedRobot).toPromise();
  }
  moveHead(direction: TurnDirection): void {
    if (this.actualTurnDirection === direction) {
      return;
    }
    this.actualTurnDirection = direction;
    this.controlService.turnHead(direction, this.selectedRobot).toPromise();
  }

  @HostListener('window:keydown', ['$event']) keydown($event: KeyboardEvent): void {
    if ($event.code === 'Tab') {
      this.chatComponent.focus();
      $event.preventDefault();
    }
    if (!$event.shiftKey) {
      clearTimeout(this.delayStopMovement);

      if ($event.code === 'KeyW') {
        this.moveRobot(MoveDirection.Forward);
      }
      if ($event.code === 'KeyS') {
        this.moveRobot(MoveDirection.Backward);
      }
      if ($event.code === 'KeyA') {
        this.moveRobot(MoveDirection.TurnLeft);
      }
      if ($event.code === 'KeyD') {
        this.moveRobot(MoveDirection.TurnRight);
      }
      if ($event.code === 'KeyQ') {
        this.moveRobot(MoveDirection.Left);
      }
      if ($event.code === 'KeyE') {
        this.moveRobot(MoveDirection.Right);
      }
    } else {
      clearTimeout(this.delayStopHead);
      if ($event.code === 'KeyW') {
        this.moveHead(TurnDirection.Up);
      }
      if ($event.code === 'KeyS') {
        this.moveHead(TurnDirection.Down);
      }
      if ($event.code === 'KeyA') {
        this.moveHead(TurnDirection.Left);
      }
      if ($event.code === 'KeyD') {
        this.moveHead(TurnDirection.Right);
      }
    }
  }

  @HostListener('window:keyup', ['$event']) keyup($event: KeyboardEvent): void {
    if (!$event.shiftKey) {
      clearTimeout(this.delayStopMovement);
      this.delayStopMovement = setTimeout(() => {
        this.moveRobot(MoveDirection.Stop);
      }, 500);
    } else {
      clearTimeout(this.delayStopHead);
      this.delayStopHead = setTimeout(() => {
        this.moveHead(TurnDirection.Center);
      }, 500);
    }
  }

  //#endregion

  //#region methods

  /** On current interaction category change, update the respective view parts */
  // currentInteractionChange(newInteractionCategory: InteractionCategory): void {
  //   this.currentInteractionTypeId = newInteractionCategory.id;
  //   this.actionTypes = newInteractionCategory.interactions;
  // }
  /** On current interaction category change, update the respective view parts */
  currentInteractionChange(newInteractionCategory: string): void {
    this.currentInteractionTypeId = newInteractionCategory;
    // this.actionTypes = newInteractionCategory.interactions;
  }

  /** On click on an action, call the api */
  executeAction(interaction: Interaction): void {
    this.chatService
      .putChatMessage(
        {
          timestamp: new Date().toISOString(),
          sender: ChatParticipant.Operator,
          kind: ChatMessage.KindEnum.InteractionMessage,
          message: interaction.command,
          action: {
            interactionType: interaction.interactionType,
            name: interaction.name,
          },
        },
        this.selectedRobot
      )
      .toPromise();
  }

  /** On current specials category change, update the respective view parts */
  currentSpecialChange(newSpecialCategory: InteractionCategory): void {
    this.currentSpecialsTypeId = newSpecialCategory.id;
    this.productTypes = newSpecialCategory.interactions;
  }

  /** On click on a play audio button, play the audio */
  customerPlayRecordedAudio(messageId): void {
    this.currentPlayingAudioId = messageId;
    // ToDo add backend call (or api call or whatever to play the audio)
  }

  /** On mark a message for training, update the backend */
  customerMarkForTraining(messageId): void {
    // ToDo add backend call
  }

  chatSendPressed(message): void {
    this.chatService
      .putChatMessage(
        {
          timestamp: new Date().toISOString(),
          sender: ChatParticipant.Operator,
          message,
          kind: ChatMessage.KindEnum.ChatMessage,
        },
        this.selectedRobot
      )
      .toPromise();
  }

  chatRecordPressed(message): void {}

  //#endregion

  //#region Helper methods

  // Todo on which occurrences should is scroll to bottom?
  /** scroll the chat to the bottom, for example at the beginning or if a new message arrived */

  chatScrollToBottom(): void {
    this.chatScrollContainer.nativeElement.scrollTop = this.chatScrollContainer.nativeElement.scrollHeight;
  }

  /** should a date header be insert based on if the date changes between two messages */
  chatShouldInsertDateHeader(chatMessage: ChatMessage, index: number): boolean {
    if (chatMessage.timestamp) {
      const tmpTime = new Date(chatMessage.timestamp);
      tmpTime.setHours(0, 0, 0, 0);
      (chatMessage as any).currTimestamp = tmpTime;
      if (index > 0) {
        const lastMessage = this.chatMessages[index - 1];
        if (!(lastMessage as any).currTimestamp) {
          return true;
        }

        if ((lastMessage as any).currTimestamp.getTime() - tmpTime.getTime() === 0) {
          return false;
        }
      }

      return true;
    }

    if (index > 0) {
      const lastMessage = this.chatMessages[index - 1];
      if ((lastMessage as any).currTimestamp) {
        (chatMessage as any).currTimestamp = (lastMessage as any).currTimestamp;
      }
    }

    return false;
  }

  /** Insert a date prefix if the date is today or yesterday */
  chatInsertDatePrefix(timestamp: string): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tmpTime = new Date(timestamp);
    tmpTime.setHours(0, 0, 0, 0);
    if (today.getTime() - tmpTime.getTime() === 0) {
      return 'today';
    }

    tmpTime.setDate(tmpTime.getDate() + 1);
    if (today.getTime() - tmpTime.getTime() === 0) {
      return 'yesterday';
    }

    return '';
  }

  //#endregion
  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  private findAndSanitizeCamera(cameras: Array<CameraStream>, cameraPosition: CameraPosition): SafeResourceUrl {
    const url = cameras?.find((camera) => camera.position === cameraPosition)?.streamUrl;
    if (url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return undefined;
  }
}
