import { Component } from '@angular/core';

// @TODO: Remove once development reaches the app screen!
// tslint:disable:template-i18n
@Component({
  selector: 'uux-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'uux';
}
