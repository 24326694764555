import { ProductActionPillModule } from './../../components/product-action-pill/product-action-pill.module';
import { ChatModule } from './../../components/chat/chat.module';
import { ChatTimestampModule } from './../../components/chat-timestamp/chat-timestamp.module';
import { ChatRobotModule } from './../../components/chat-robot/chat-robot.module';
import { ChatCustomerModule } from './../../components/chat-customer/chat-customer.module';
import { ActionPillModule } from './../../components/action-pill/action-pill.module';
import { LabelModule } from './../../components/label/label.module';
import { FilterPillModule } from './../../components/filter-pill/filter-pill.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainChatScreenComponent } from './main-chat-screen.component';
import { NeedsIframePipe } from './needs-iframe.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { CameraStreamModule } from '../../components/camera-stream/camera-stream.module';
import { IconButtonModule } from '../../components/icon-button/icon-button.module';

@NgModule({
  declarations: [MainChatScreenComponent, NeedsIframePipe],
  imports: [
    CommonModule,
    FilterPillModule,
    LabelModule,
    ActionPillModule,
    ChatCustomerModule,
    ChatRobotModule,
    ChatTimestampModule,
    ChatModule,
    ProductActionPillModule,
    OverlayModule,
    CameraStreamModule,
    IconButtonModule,
  ],
  exports: [MainChatScreenComponent],
})
export class MainChatScreenModule {}
