import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatTimestampComponent } from './chat-timestamp.component';

@NgModule({
  declarations: [ChatTimestampComponent],
  imports: [CommonModule],
  exports: [ChatTimestampComponent],
})
export class ChatTimestampModule {}
