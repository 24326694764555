import { MainChatScreenComponent } from './screens/main-chat-screen/main-chat-screen.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'Chat', redirectTo: 'Chat/pepper', pathMatch: 'full' },
  { path: 'Chat/:robot', component: MainChatScreenComponent },
  { path: '', redirectTo: 'Chat/pepper', pathMatch: 'full' },
  /*{ path: "Training", component: SomeViewComponent }, // ToDo Insert View Component here!
  { path: "System", component: SomeViewComponent }, // ToDo Insert View Component here!
  { path: "User", component: SomeViewComponent }, // ToDo Insert View Component here!*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
